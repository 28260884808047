import React from "react";
import * as s from "../styles/website";

const PresentationLong = () => {
    return (
        <h2 style={s.h1}>
            <i>Hello there!</i> <br/>Name's <u>Kevin</u>. I'm a <br/>
            <code style={s.code}>Software Engineer<br />&amp;<br />SaaS Creator</code>
        </h2>
    );
}

export default PresentationLong;
